import * as Api from '@/api'

const GET_DASHBOARD_APPS = '/apps/'
const AUTH = '/app_auth/:id'

export default {
  get () {
    return Api.http(true).get(GET_DASHBOARD_APPS)
  },
  getAuth (id) {
    return Api.http(true).get(AUTH.replace(':id', id))
  }
}
