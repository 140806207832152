import { store } from '@customer-apps/styleguide'
import * as Constants from '@/store/constants'
import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

const isLogged = (to, from, next) => {
  // console.log('isLogged')
  Vue.prototype.$loading(true)
  // if (typeof store.state !== 'undefined' && store.state.auth.access && (new Date(store.state.expired) > new Date())) {
  if (typeof store.state !== 'undefined' && store.state.auth.access) {
    next()
    Vue.prototype.$loading(false)
    return
  }
  // store.commit('resetState')
  next(Constants.URL_LOGIN)
  Vue.prototype.$loading(false)
  return {}
}
export default isLogged
