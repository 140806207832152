import { store } from '@customer-apps/styleguide'
import * as Constants from '@/store/constants'
import loginRedirect from '@/router/middlewares/loginRedirect'
import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

const homeRedirect = (to, from, next) => {
  // console.log('homeRedirect')
  Vue.prototype.$loading(true)

  // if (typeof store.state !== 'undefined' && store.state.auth.access && (new Date(store.state.expired) > new Date())) {
  if (typeof store.state !== 'undefined' && store.state.auth.access) {
    next(Constants.URL_DASHBOARD)
    Vue.prototype.$loading(false)
    return {}
  }

  // store.commit('resetState')
  // store.commit('resetState')
  if (Constants.DEBUG) {
    loginRedirect(to, from, next)
    Vue.prototype.$loading(false)
    return {}
  }
  next(Constants.URL_LOGIN)
  Vue.prototype.$loading(false)
  return {}
}
export default homeRedirect
