import Vue from 'vue'
import VueRouter from 'vue-router'
import client from '@/router/types/client'
import internal from '@/router/types/internal'
import * as Constants from '@/store/constants'

Vue.use(VueRouter)

var routes = []
switch (Constants.AUTH_TYPE) {
  case 2:
    routes = internal
    break
  case 1:
    routes = client
    break
  default:
    // MeoId
    routes = client
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
