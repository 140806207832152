// import { store } from '@customer-apps/styleguide'
import * as Constants from '@/store/constants'
import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

import ApiApp from '@/api/services/App.js'

Vue.use(VueLoading)

const whiteList = (to, from, next) => {
  Vue.prototype.$loading(true)
  var valid = false
  ApiApp.get().then(response => {
    var apps = response.data.results
    apps.forEach(item => {
      if (item.base.includes(to.path)) {
        valid = true
      }
    })
    if (!valid) {
      window.location = Constants.BASEURL
      Vue.prototype.$loading(false)
      return
    }
    next()
    Vue.prototype.$loading(false)
    return {}
  })
}
export default whiteList
