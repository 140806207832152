import Auth from '@/api/services/Auth'
import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

const logout = (to, from, next) => {
  Vue.prototype.$loading(true)
  next()
  Auth.logout()
  Vue.prototype.$loading(false)
  return {}
}
export default logout
