import { store } from '@customer-apps/styleguide'
import * as Constants from '@/store/constants'
import Auth from '@/api/services/Auth.js'

import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

const loginRedirect = (to, from, next) => {
  // console.log('loginRedirect')
  Vue.prototype.$loading(true)
  if (to.query.code) {
    // store.commit('resetState')
    Auth.loginMeo(to.query.code).then((result) => {
      if (result.data.profile.length === 0) {
        next({ path: Constants.URL_NEW_USER, query: { data: result.data } })
      } else {
        store.commit('authentication', result.data)
        // store.commit('setToken', result.data.access.id_token)
        store.commit('setDisclaimer', result.data.profile[0].disclaimer)
        store.commit('setName', result.data.info.name)
        if ((store.state.disclaimer === null || store.state.disclaimer === '') && store.state.internal !== true) {
          next({ path: Constants.URL_TERMS })
          Vue.prototype.$loading(false)
          return {}
        }
        next(Constants.URL_DASHBOARD)
        Vue.prototype.$loading(false)
        return {}
      }
    })
    return
  // } else if (typeof store.state !== 'undefined' && store.state.auth.access && (new Date(store.state.expired) > new Date())) {
  } else if (typeof store.state !== 'undefined' && store.state.auth.access) {
    next(Constants.URL_DASHBOARD)
    Vue.prototype.$loading(false)
    return
  }
  switch (Constants.AUTH_TYPE) {
    case 2:
      next(Constants.URL_LOGIN)
      Vue.prototype.$loading(false)
      break
    default:
      window.location = Constants.MEOID_LOGIN
      Vue.prototype.$loading(false)
  }
  return {}
}
export default loginRedirect
