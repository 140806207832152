import * as Api from '@/api'

import * as Constants from '@/store/constants'

import { store } from '@customer-apps/styleguide'

const LOGIN = '/auth/token/'
const MSTR_LOGOUT = '/microstrategy/logout/'
const RESET_PASSWORD = '/password_reset/'
const RESET_PASSWORD_CONFIRM = '/password_reset/confirm/'
const CHANGE_PASSWORD = '/change_password/'
const DISCLAIMER = '/disclaimers/'

const MEO_LOGIN = '/meoid/:code'
const MEO_CONNECT = '/meoid/connect/'

const AD_LOGIN = '/active-directory/token/'

export default {
  login (data) {
    return Api.http(false).post(LOGIN, data)
  },
  loginMeo (data) {
    return Api.http(false).get(MEO_LOGIN.replace(':code', data))
  },
  loginAd (data) {
    return Api.http(false).post(AD_LOGIN, data)
  },
  connectMeo (data) {
    return Api.http(false).post(MEO_CONNECT, data)
  },
  resetPassword (data) {
    return Api.http(false).post(RESET_PASSWORD, data)
  },
  resetPasswordConfirm (data) {
    return Api.http(false).post(RESET_PASSWORD_CONFIRM, data)
  },
  changePassword (data) {
    return Api.http(true).put(CHANGE_PASSWORD, data)
  },
  logout () {
    // console.log(typeof store.state !== 'undefined')

    const ticketSession = store.tickets.session
    const pmSession = store.pm.session

    // check if user has microstrategy sessions created
    if (ticketSession || pmSession) {
      // call logout endpoint
      Api.http(true).post(MSTR_LOGOUT, { ticketSession: ticketSession, pmSession: pmSession })
    }

    // clean vuex
    const token = store.state.token.valueOf()
    if (!token) {
      window.location = '/'
    } else if (Constants.AUTH_TYPE === 1) {
      store.commit('resetState')
      window.location = Constants.MEOID_LOGOUT.replace('{id_token}', token)
    } else {
      store.commit('resetState')
      window.location = '/'
    }
  },
  setDisclaimer () {
    return Api.http(true).put(DISCLAIMER, {})
  },
  getDisclaimerUrl () {
    return Api.http(false).get(DISCLAIMER)
  }
}
