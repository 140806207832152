import homeRedirect from '@/router/middlewares/homeRedirect'
import loginRedirect from '@/router/middlewares/loginRedirect'
import isLogged from '@/router/middlewares/isLogged'
import whiteList from '@/router/middlewares/whiteList'
import logout from '@/router/middlewares/logout'
import * as Constants from '@/store/constants'

const MainLayout = () => import(/* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue')
// const DefaultHeader = () => import(/* webpackChunkName: "default-header" */ '@/layouts/DefaultHeader.vue')
// const SimpleHeader = () => import(/* webpackChunkName: "simple-header" */ '@/layouts/SimpleHeader.vue')
const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/components/Footer.vue')

const routes = [
  {
    path: '/app/*',
    name: 'Whitelist',
    beforeEnter: whiteList
  },
  {
    path: '',
    component: MainLayout,
    props: true,
    children: [
      {
        path: '/',
        name: 'Home',
        beforeEnter: homeRedirect
      },
      {
        path: Constants.URL_LOGIN,
        name: 'LoginMeoID',
        beforeEnter: loginRedirect
      },
      // System
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: {
          default: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
          footer: DefaultFooter
        },
        beforeEnter: isLogged
      },
      {
        path: Constants.URL_SEED !== '' ? Constants.URL_SEED : '/seed',
        name: 'Seed',
        beforeEnter: homeRedirect
      },
      {
        path: Constants.URL_NEW_USER,
        components: {
          default: () => import(/* webpackChunkName: "new-user" */ '@/views/NewUser.vue'),
          footer: DefaultFooter
        }
      },
      {
        path: Constants.URL_LOGOUT,
        name: 'LogoutMeoID',
        props: {
          default: {
            img: '/img/logo-altice-empresas-h-b.png',
            title: 'Customer Apps'
          }
        },
        components: {
          default: () => import(/* webpackChunkName: "logout" */ '@/layouts/Logout.vue'),
          footer: DefaultFooter
        },
        beforeEnter: logout
      },
      {
        path: Constants.URL_TERMS,
        name: 'Disclaimer',
        components: {
          default: () => import(/* webpackChunkName: "disclaimer" */ '@/views/Disclaimer.vue'),
          footer: DefaultFooter
        },
        beforeEnter: isLogged
      },
      {
        path: '/*',
        name: 'Monitoring',
        beforeEnter: isLogged
      }
    ]
  }
]
export default routes
